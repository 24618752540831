export interface ReportMetadata {
  reportName: string;
  reportDescription: string;
  sourceFileS3Paths: string[];
}

export enum SourceType {
  TERM_SHEET = "TERM_SHEET",
  RENT_ROLL = "RENT_ROLL",
  OPERATING_STATEMENT = "OPERATING_STATEMENT",
  APPRAISAL = "APPRAISAL",
  PFS = "PFS",
  OTHER = "OTHER",
}

export interface BaseReport {
  reportId: string;
  userId: string;
  reportMetadata: ReportMetadata;
  data: Record<string, Record<string, Answer[]>>;
}

export interface Answer {
  value: string;
  type: "string" | "number" | "date" | "boolean";
  unit?: string;
  citation?: string;
}

export interface Question {
  prompt: string;
  options?: string[];
  multiline?: boolean;
  type: "string" | "number" | "date" | "boolean";
  condition?: Condition;
}

export interface Condition {
  name: string;
  value: string;
  effect: "match" | "notMatch";
}
