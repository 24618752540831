import { FunctionComponent, useState } from "react";
import { Answer } from "../../common/types";
import Grid from "@cloudscape-design/components/grid";
import Input from "@cloudscape-design/components/input";
import {
  FormField,
  KeyValuePairs,
  Textarea,
} from "@cloudscape-design/components";

export const AnswerView: FunctionComponent<{
  answer: Answer;
  onChange: (a: Answer) => void;
}> = ({ answer, onChange }) => {
  return (
    <Grid gridDefinition={[{ colspan: 2 }, { colspan: 2 }, { colspan: 8 }]}>
      <Input
        value={answer.value}
        onChange={(e) => onChange({ ...answer, value: e.detail.value })}
      />
      <Input
        value={answer.unit || ""}
        onChange={(e) => onChange({ ...answer, unit: e.detail.value })}
      />
      <Input
        value={answer.citation || ""}
        onChange={(e) => onChange({ ...answer, citation: e.detail.value })}
      />
    </Grid>
  );
};
