const localDevStageOverride = "alpha";

export class EndpointConfigs {
  static featureExtractionApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.feature-extraction.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.feature-extraction.flux-systems.com";
    }
    return "https://beta.feature-extraction.flux-systems.com";
  }

  static savedReportsApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.saved-reports.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.saved-reports.flux-systems.com";
    }
    return "https://beta.saved-reports.flux-systems.com";
  }
}
