// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from "react";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import Grid from "@cloudscape-design/components/grid";
import SpaceBetween from "@cloudscape-design/components/space-between";

function HeroHeader() {
  return (
    <Box data-testid="hero-header" padding={{ top: "xs", bottom: "l" }}>
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xs: 8, s: 9 } },
          { colspan: { default: 12, xs: 4, s: 3 } },
        ]}
      >
        <div>
          <Box variant="h1">Flux</Box>
          <Box
            variant="p"
            color="text-body-secondary"
            margin={{ top: "xxs", bottom: "s" }}
          >
            Delivering data insights and analytics to make decisions quickly,
            and at scale. Enhance your next step decision-making through
            actionable insights with a free trial today.
          </Box>
          <SpaceBetween size="xs">
            <div>Tags: Free trial | Vendor insights | Quick launch</div>
          </SpaceBetween>
        </div>

        <Box margin={{ top: "l" }}>
          <SpaceBetween size="s">
            <Button variant="primary" fullWidth={true}>
              Get started
            </Button>
            <Button fullWidth={true}>View demo</Button>
          </SpaceBetween>
        </Box>
      </Grid>
    </Box>
  );
}

export { HeroHeader };
