import * as React from "react";
import { Container, ContentLayout, Link } from "@cloudscape-design/components";

import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import { CollectionWizard } from "../component/wizard/CollectionWizard";
import { SavedReportsTable } from "../component/table/SavedReportsTable";
import { PreviewBoard } from "../component/preview/PreviewBoard";
import { BaseReport } from "../common/types";
import { listReports } from "../api/savedReports";

export const Demo = () => {
  const [generating, setGenerating] = React.useState(false);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [baseReport, setBaseReport] = React.useState<BaseReport | null>(null);

  const [savedReports, setSavedReports] = React.useState<BaseReport[]>([]);

  React.useEffect(() => {
    setTableLoading(true);
    listReports("demo-user")
      .then((reports) => {
        console.log("Fetched saved reports", reports);
        setSavedReports(reports);
      })
      .catch((error) => {
        console.error("Failed to fetch saved reports", error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, []);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Product Demo
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <Container>
          <CollectionWizard
            onSubmit={() => {
              setGenerating(true);
            }}
            onSuccess={(baseReport: BaseReport) => {
              setBaseReport(baseReport);
              setGenerating(false);
            }}
            onCancel={() => {
              setGenerating(false);
            }}
          />
        </Container>
        <PreviewBoard loading={generating} baseReport={baseReport} />
        <SavedReportsTable
          savedReports={savedReports}
          onRefresh={() => {
            setTableLoading(true);
            listReports("demo-user")
              .then((reports) => {
                console.log("Fetched saved reports", reports);
                setSavedReports(reports);
              })
              .catch((error) => {
                console.error("Failed to fetch saved reports", error);
              })
              .finally(() => {
                setTableLoading(false);
              });
          }}
          onReportClick={(report) => {
            setBaseReport(report);
          }}
          loading={tableLoading}
        />
      </SpaceBetween>
    </ContentLayout>
  );
};
