import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import Box from "@cloudscape-design/components/box";
import Container from "@cloudscape-design/components/container";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import Header from "@cloudscape-design/components/header";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { FunctionComponent, useState } from "react";

import { BaseReport } from "../../common/types";
import { AnswerView } from "./AnswerView";

export interface PreviewItem {
  label: string;
  value: string | number;
  multiline?: boolean;
}

const convertCamelCaseToTitleCase = (camelCase: string) => {
  const camelCasePattern = /([a-z])([A-Z])/g;
  const titleCase = camelCase.replace(camelCasePattern, "$1 $2");
  return titleCase.replace(/\b\w/g, (char) => char.toUpperCase());
};

const PreviewContent: FunctionComponent<{
  baseReport: BaseReport;
}> = ({ baseReport }) => {
  const [comment, setComment] = useState("");
  return (
    <Container header={<Header variant="h2">Preview</Header>}>
      <ExpandableSection headerText="General information" defaultExpanded>
        <KeyValuePairs
          columns={4}
          items={[
            {
              label: "Report ID",
              value: baseReport.reportId,
            },
            {
              label: "User ID",
              value: baseReport.userId,
            },
            {
              label: "Report name",
              value: baseReport.reportMetadata.reportName,
            },
            {
              label: "Report description",
              value: baseReport.reportMetadata.reportDescription,
            },
          ]}
        />
      </ExpandableSection>
      {Object.entries(baseReport.data || {}).map(([namespace, collection]) => (
        <ExpandableSection
          key={namespace}
          headerText={convertCamelCaseToTitleCase(namespace)}
        >
          <Box padding={{ left: "m" }}>
            {Object.entries(collection).map(([collectionName, items]) => (
              <ExpandableSection
                key={collectionName}
                headerText={convertCamelCaseToTitleCase(collectionName)}
                defaultExpanded
              >
                <SpaceBetween size={"s"}>
                  {items.map((item, index) => (
                    <AnswerView key={index} answer={item} onChange={() => {}} />
                  ))}
                </SpaceBetween>
              </ExpandableSection>
            ))}
          </Box>
        </ExpandableSection>
      ))}
    </Container>
  );
};

const PreviewLoading = () => {
  return (
    <Container header={<Header variant="h2">Preview</Header>}>
      <div aria-live="polite">
        <LoadingBar variant="gen-ai" />
      </div>
    </Container>
  );
};

export const PreviewBoard: FunctionComponent<{
  baseReport: BaseReport | null;
  loading?: boolean;
}> = ({ loading, baseReport }) => {
  if (!baseReport) return null;
  return <PreviewContent baseReport={baseReport} />;
};
