import * as React from "react";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import { Container } from "@cloudscape-design/components";

export interface StepInfo {
  label: string;
  description: string;
  value: number;
  additionalInfo?: string;
  status?: "success" | "error";
}

export const ProgressView: React.FunctionComponent<{
  step: StepInfo;
}> = ({ step }) => {
  return (
    <ProgressBar
      status={step.status}
      value={step.value}
      label={step.label}
      description={step.description}
      additionalInfo={step.additionalInfo}
      variant="key-value"
    />
  );
};

export enum GenerationSteps {
  INITIALIZING = "INITIALIZING",
  EXTRACT_FEATURES_TERM_SHEET = "EXTRACT_FEATURES_TERM_SHEET",
  EXTRACT_FEATURES_OPERATING_STATEMENT = "EXTRACT_FEATURES_OPERATING_STATEMENT",
  EXTRACT_FEATURES_APPRAISAL = "EXTRACT_FEATURES_APPRAISAL",
  EXTRACT_FEATURES_RENT_ROLL = "EXTRACT_FEATURES_RENT_ROLL",
  EXTRACT_FEATURES_PFS = "EXTRACT_FEATURES_PFS",
  EXTRACT_FEATURES_OTHER = "EXTRACT_FEATURES_OTHER",
  SAVING_RESULTS = "SAVING_RESULTS",
  SUCCESS = "SUCCESS",
}

export const GenerationStepInfo: Record<GenerationSteps, StepInfo> = {
  [GenerationSteps.INITIALIZING]: {
    value: 0,
    label: "Initializing",
    description: "Initializing the feature extraction process",
  },
  [GenerationSteps.EXTRACT_FEATURES_TERM_SHEET]: {
    value: 20,
    label: "Extract Features",
    description: "Extracting features from term sheet",
  },
  [GenerationSteps.EXTRACT_FEATURES_OPERATING_STATEMENT]: {
    value: 30,
    label: "Extract Features",
    description: "Extracting features from operating statement",
  },
  [GenerationSteps.EXTRACT_FEATURES_APPRAISAL]: {
    value: 40,
    label: "Extract Features",
    description: "Extracting features from appraisal",
  },
  [GenerationSteps.EXTRACT_FEATURES_RENT_ROLL]: {
    value: 60,
    label: "Extract Features",
    description: "Extracting features from rent roll",
  },
  [GenerationSteps.EXTRACT_FEATURES_PFS]: {
    value: 70,
    label: "Extract Features",
    description: "Extracting features from personal financial statement",
  },
  [GenerationSteps.EXTRACT_FEATURES_OTHER]: {
    value: 80,
    label: "Extract Features",
    description: "Extracting features from other documents",
  },
  [GenerationSteps.SAVING_RESULTS]: {
    value: 90,
    label: "Saving Results",
    description: "Saving the extracted features",
  },
  [GenerationSteps.SUCCESS]: {
    value: 100,
    label: "Succeeded",
    description: "Features extracted successfully",
    status: "success",
  },
};
