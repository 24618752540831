import Wizard from "@cloudscape-design/components/wizard";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { FunctionComponent, useEffect, useState } from "react";
import Link from "@cloudscape-design/components/link";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import FileUpload from "@cloudscape-design/components/file-upload";
import { BaseReport, ReportMetadata, SourceType } from "../../common/types";
import { createReport, saveBaseReport } from "../../api/savedReports";
import { extractFeatures, uploadFile } from "../../api/featureExtraction";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import { BasicInformation } from "./BasicInformation";
import { GenerationStepInfo, ProgressView, StepInfo } from "./ProgressView";

export const CollectionWizard: FunctionComponent<{
  onSubmit: () => void;
  onSuccess: (baseReports: BaseReport) => void;
  onCancel: () => void;
}> = ({ onSubmit, onSuccess, onCancel }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [termSheet, setTermSheet] = useState<File[]>([]);
  const [operatingStatement, setOperatingStatement] = useState<File[]>([]);
  const [rentRoll, setRentRoll] = useState<File[]>([]);
  const [appraisal, setAppraisal] = useState<File[]>([]);
  const [pfs, setPfs] = useState<File[]>([]);
  const [otherDocuments, setOtherDocuments] = useState<File[]>([]);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [stepInfo, setStepInfo] = useState<StepInfo | undefined>(undefined);

  const userId = "demo-user"; // TODO: Get user id from auth context
  const [reportMetadata, setReportMetadata] = useState<ReportMetadata>({
    reportName: "",
    reportDescription: "",
    sourceFileS3Paths: ["-"],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errors = termSheet.map((file) => {
      if (file.type !== "application/pdf") {
        return "Please upload files in PDF format";
      }
      return "";
    });
    setFileErrors(errors);
  }, [termSheet]);

  const uploadFiles = async (files: File[], sourceType: SourceType) => {
    const s3FilePaths = await Promise.all(
      files.map((file) =>
        uploadFile(file, { source_type: sourceType, user_id: userId })
      )
    );
    return s3FilePaths;
  };

  const conductFeatureExtraction = async () => {
    setStepInfo(GenerationStepInfo.INITIALIZING);
    const reportId = await createReport(userId, reportMetadata);
    console.log("Report Created with ID: ", reportId);
    const sourceFileS3Paths: string[] = [];
    if (!reportId) {
      console.error("Failed to create report");
      return;
    }
    setStepInfo(GenerationStepInfo.EXTRACT_FEATURES_TERM_SHEET);
    const termSheetS3FilePaths = await uploadFiles(
      termSheet,
      SourceType.TERM_SHEET
    );
    sourceFileS3Paths.push(...termSheetS3FilePaths);
    const termSheetResults = await extractFeatures(
      termSheetS3FilePaths,
      SourceType.TERM_SHEET
    );
    setStepInfo(GenerationStepInfo.EXTRACT_FEATURES_OPERATING_STATEMENT);
    const operatingStatementS3FilePaths = await uploadFiles(
      operatingStatement,
      SourceType.OPERATING_STATEMENT
    );
    sourceFileS3Paths.push(...operatingStatementS3FilePaths);
    const operatingStatementResults = await extractFeatures(
      operatingStatementS3FilePaths,
      SourceType.OPERATING_STATEMENT
    );
    setStepInfo(GenerationStepInfo.EXTRACT_FEATURES_RENT_ROLL);
    const rentRollS3FilePaths = await uploadFiles(
      rentRoll,
      SourceType.RENT_ROLL
    );
    sourceFileS3Paths.push(...rentRollS3FilePaths);
    const rentRollResults = await extractFeatures(
      rentRollS3FilePaths,
      SourceType.RENT_ROLL
    );
    setStepInfo(GenerationStepInfo.EXTRACT_FEATURES_APPRAISAL);
    const appraisalS3FilePaths = await uploadFiles(
      appraisal,
      SourceType.APPRAISAL
    );
    sourceFileS3Paths.push(...appraisalS3FilePaths);
    const appraisalResults = await extractFeatures(
      appraisalS3FilePaths,
      SourceType.APPRAISAL
    );
    setStepInfo(GenerationStepInfo.EXTRACT_FEATURES_PFS);
    const pfsS3FilePaths = await uploadFiles(pfs, SourceType.PFS);
    sourceFileS3Paths.push(...pfsS3FilePaths);
    const pfsResults = await extractFeatures(pfsS3FilePaths, SourceType.PFS);
    setStepInfo(GenerationStepInfo.EXTRACT_FEATURES_OTHER);
    const otherDocumentsS3FilePaths = await uploadFiles(
      otherDocuments,
      SourceType.OTHER
    );
    sourceFileS3Paths.push(...otherDocumentsS3FilePaths);
    const otherResults = await extractFeatures(
      otherDocumentsS3FilePaths,
      SourceType.OTHER
    );
    setStepInfo(GenerationStepInfo.SAVING_RESULTS);
    return {
      reportId,
      userId,
      reportMetadata: { ...reportMetadata, sourceFileS3Paths },
      data: {
        termSheetResults,
        operatingStatementResults,
        rentRollResults,
        appraisalResults,
        pfsResults,
        otherResults,
      },
    };
  };

  if (stepInfo) {
    return <ProgressView step={stepInfo} />;
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const results = await conductFeatureExtraction();
      console.log(results);
      if (!results) throw new Error("Failed to extract features");
      await saveBaseReport(results);
      setStepInfo(GenerationStepInfo.SUCCESS);
      onSuccess(results);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    setStepInfo(undefined);
  };

  const i18nStrings = {
    uploadButtonText: (e: any) => (e ? "Choose files" : "Choose file"),
    dropzoneText: (e: any) =>
      e ? "Drop files to upload" : "Drop file to upload",
    removeFileAriaLabel: (e: number) => `Remove file ${e + 1}`,
    limitShowFewer: "Show fewer files",
    limitShowMore: "Show more files",
    errorIconAriaLabel: "Error",
  };

  const CHOOSE_FILE = "Choose file from your device";
  const CONSTRAINT_TEXT = "Please upload files in PDF format";

  return (
    <Wizard
      onSubmit={() => {
        onSubmit();
        handleSubmit();
      }}
      onCancel={() => {
        setActiveStepIndex(0);
        onCancel();
      }}
      isLoadingNextStep={loading}
      i18nStrings={{
        stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) =>
          `Step ${stepNumber} of ${stepsCount}`,
        skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
        navigationAriaLabel: "Steps",
        cancelButton: "Cancel",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: "Generate",
        optional: "optional",
      }}
      onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
      activeStepIndex={activeStepIndex}
      allowSkipTo
      steps={[
        {
          title: "Basic Information",
          info: <Link variant="info">Info</Link>,
          description: "Provide some descriptions for the case",
          content: (
            <BasicInformation
              reportMetadata={reportMetadata}
              onChange={setReportMetadata}
            />
          ),
        },
        {
          title: "Upload Source Documents",
          info: <Link variant="info">Info</Link>,
          description: "First we need to collect some information",
          content: (
            <Container>
              <SpaceBetween direction="vertical" size="m">
                <FormField
                  label="Term Sheet"
                  description={CHOOSE_FILE}
                  info={<Link variant="info">Info</Link>}
                >
                  <FileUpload
                    onChange={({ detail }) => setTermSheet(detail.value)}
                    value={termSheet}
                    fileErrors={fileErrors}
                    i18nStrings={i18nStrings}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText={CONSTRAINT_TEXT}
                  />
                </FormField>
                <FormField
                  label="Operating Statement"
                  description={CHOOSE_FILE}
                  info={<Link variant="info">Info</Link>}
                >
                  <FileUpload
                    onChange={({ detail }) =>
                      setOperatingStatement(detail.value)
                    }
                    value={operatingStatement}
                    fileErrors={fileErrors}
                    i18nStrings={i18nStrings}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText={CONSTRAINT_TEXT}
                  />
                </FormField>
                <FormField
                  label="Rent Roll"
                  description={CHOOSE_FILE}
                  info={<Link variant="info">Info</Link>}
                >
                  <FileUpload
                    onChange={({ detail }) => setRentRoll(detail.value)}
                    value={rentRoll}
                    fileErrors={fileErrors}
                    i18nStrings={i18nStrings}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText={CONSTRAINT_TEXT}
                  />
                </FormField>
                <FormField
                  label="Appraisal"
                  description={CHOOSE_FILE}
                  info={<Link variant="info">Info</Link>}
                >
                  <FileUpload
                    onChange={({ detail }) => setAppraisal(detail.value)}
                    value={appraisal}
                    fileErrors={fileErrors}
                    i18nStrings={i18nStrings}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText={CONSTRAINT_TEXT}
                  />
                </FormField>
                <FormField
                  label="Personal Financial Statement"
                  description={CHOOSE_FILE}
                  info={<Link variant="info">Info</Link>}
                >
                  <FileUpload
                    onChange={({ detail }) => setPfs(detail.value)}
                    value={pfs}
                    fileErrors={fileErrors}
                    i18nStrings={i18nStrings}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText={CONSTRAINT_TEXT}
                  />
                </FormField>
                <FormField
                  label="Other Documents"
                  description={CHOOSE_FILE}
                  info={<Link variant="info">Info</Link>}
                >
                  <FileUpload
                    onChange={({ detail }) => setOtherDocuments(detail.value)}
                    value={otherDocuments}
                    fileErrors={fileErrors}
                    i18nStrings={i18nStrings}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText={CONSTRAINT_TEXT}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          ),
        },
        {
          title: "Review and Generate",
          content: (
            <SpaceBetween size="xs">
              <Header
                variant="h3"
                actions={
                  <Button onClick={() => setActiveStepIndex(0)}>Edit</Button>
                }
              >
                Source Documents
              </Header>
              <KeyValuePairs
                columns={3}
                items={[
                  {
                    label: "User ID",
                    value: userId,
                  },
                  {
                    label: "Report Name",
                    value: reportMetadata.reportName,
                  },
                  {
                    label: "Report Description",
                    value: reportMetadata.reportDescription,
                  },
                  {
                    label: "Term Sheet",
                    value: termSheet.map((file) => file.name).join(", "),
                  },
                  {
                    label: "Operating Statement",
                    value: operatingStatement
                      .map((file) => file.name)
                      .join(", "),
                  },
                  {
                    label: "Rent Roll",
                    value: rentRoll.map((file) => file.name).join(", "),
                  },
                  {
                    label: "Appraisal",
                    value: appraisal.map((file) => file.name).join(", "),
                  },
                  {
                    label: "Personal Financial Statement",
                    value: pfs.map((file) => file.name).join(", "),
                  },
                  {
                    label: "Other Documents",
                    value: otherDocuments.map((file) => file.name).join(", "),
                  },
                ]}
              />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
