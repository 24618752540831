import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { Answer } from "../common/types";

export interface FileMetadata {
  source_type: string;
  user_id: string;
}

export const FEATURE_EXTRACTION_API_URL =
  EndpointConfigs.featureExtractionApiUrl();

const encodePdfFileBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const encodeBase64RemovePrefix = async (file: File) => {
  const res = (await encodePdfFileBase64(file)) as string;
  return res.split(",")[1];
};

export const uploadFile = async (file: File, metadata: FileMetadata) => {
  const base64encoded = await encodeBase64RemovePrefix(file);
  const response = await axios.post(
    `${FEATURE_EXTRACTION_API_URL}/upload`,
    {
      content: base64encoded,
      metadata: metadata,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to upload file");
  }
  return response.data.s3_file_path as string;
};

export const extractFeatures = async (
  s3FilePaths: string[],
  sourceType: string
) => {
  if (s3FilePaths.length === 0) {
    return {}; // FOR TESTING
  }
  const response = await axios.post(
    `${FEATURE_EXTRACTION_API_URL}/analyze`,
    {
      s3_file_paths: s3FilePaths,
      source_type: sourceType,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to extract features");
  }
  return response.data.results as Record<string, Answer[]>;
};
